import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faDollarSign,
  faShoppingBasket,
  faUserCircle,
  faRedo,
  faCamera,
  faBaby,
  faBabyCarriage,
  faBookReader,
  faAward,
  faTrophy,
  faBuilding,
  faUtensils,
  faStar,
  faEye,
  faPlayCircle,
  faMapMarker
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faDollarSign,
  faCheckCircle,
  faShoppingBasket,
  faUserCircle,
  faRedo,
  faCamera,
  faBaby,
  faBabyCarriage,
  faBookReader,
  faAward,
  faTrophy,
  faBuilding,
  faUtensils,
  faStar,
  faEye,
  faPlayCircle,
  faMapMarker
);
