import React, { Component } from "react";
import Routes from "./routes/Routes";
import styled from "styled-components";
import "./icons";

const StyledAppWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  width: 100%;
`;

class App extends Component {
  render() {
    return (
      <StyledAppWrapper>
        <Routes />
      </StyledAppWrapper>
    );
  }
}

export default App;
