import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AdminRoute from "./AdminRoute";
import LoadableWrapper from "./LoadableWrapper";

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route
          exact
          path="/"
          component={LoadableWrapper({
            loader: () => import("../home/HomeContainer")
          })}
        />
        <AdminRoute
          exact
          path="/admin"
          component={LoadableWrapper({
            loader: () => import("../admin/AdminContainer")
          })}
        />
        <Route
          component={LoadableWrapper({
            loader: () => import("../notfound/NotFound")
          })}
        />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Routes;
