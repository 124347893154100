import React from "react";
import Loading from "../common/Loading";

const LoadableLoading = ({ error, pastDelay, timedOut }) => {
  if (error) {
    return <div>Error in LoadableLoading!</div>;
  } else if (timedOut) {
    return <Loading />;
  } else if (pastDelay) {
    return <Loading />;
  } else {
    return <Loading />;
  }
};

export default LoadableLoading;
