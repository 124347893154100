import React, { Component } from "react";
import styled from "styled-components";

const StyledLoading = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export default class Loading extends Component {
  render() {
    return (
      <StyledLoading>
        <img
          src="img/large_logo.png"
          alt="Logo"
          style={{ width: "100%", height: "auto", maxWidth: 400 }}
        />
      </StyledLoading>
    );
  }
}
