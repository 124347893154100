export default function(
  { minCapacity, maxCapacity },
  arrayValues,
  otherFilters
) {
  const conditions = [];

  //find out if element is in an array
  for (let i = 0; i < arrayValues.length; i++) {
    const arrayValue = arrayValues[i];

    if (arrayValue.value.length) {
      conditions.push(element => {
        return arrayValue.value.indexOf(element[arrayValue.type]) > -1;
      });
    }
  }

  for (const filter in otherFilters) {
    const filterValue = otherFilters[filter];

    if (filter === "rating") {
      if (filterValue === "viewAllRatedCenters") {
        conditions.push(element => {
          return element[filter] >= 1;
        });
        continue;
      }
    }

    if (filterValue) {
      conditions.push(element => {
        return element[filter] === filterValue;
      });
    }
  }

  conditions.push(element => {
    return (
      element.licensedCapacity >= minCapacity &&
      element.licensedCapacity <= maxCapacity
    );
  });

  return conditions;
}
